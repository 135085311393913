import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { GetServerSidePropsContext } from 'next'
import { CraftGlobals } from 'tsconfig/craft-types'
import { getSiteInfo } from 'shared'
import getGlobals from '../graphql/get-globals'
import DefaultLayout from '../layouts/default'
import { defaultClient } from '../prismic/clients'
import { PrismicMetaSingle, PrismicStartPageSingle } from '../prismic-types-d'
import { CLanding } from '../components/organisms/c-landing'
import { CSeo } from '../components/organisms/c-seo'
import { setCacheHeaders } from '../helpers/cache-config'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const siteInfo = getSiteInfo(context.locale ?? '')

  const global = await getGlobals(siteInfo.handle)

  let startPage, meta

  try {
    startPage = await defaultClient.getSingle('start_page', {
      lang: context.locale?.toLowerCase(),
    })

    const metaRes: PrismicMetaSingle = await defaultClient.getSingle('meta', {
      lang: context.locale?.toLowerCase(),
    })
    meta = metaRes.data
  } catch {
    return {
      notFound: true,
    }
  }

  setCacheHeaders(context.res)

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? '', ['common'])),
      global,
      meta,
      startPage,
    },
  }
}

type Props = {
  global: CraftGlobals
  startPage: PrismicStartPageSingle
  meta: PrismicMetaSingle['data']
}

export default function Index({ global, startPage, meta }: Props) {
  return (
    <DefaultLayout global={global}>
      <CSeo meta={meta} />
      <CLanding content={startPage.data} />
    </DefaultLayout>
  )
}

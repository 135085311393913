import gsap from 'gsap'
import { useEffect } from 'react'
import css from 'styled-jsx/css'
import { AButton } from 'ui'
import TagManager from 'react-gtm-module'
import { PrismicStartPageSingle } from '../../prismic-types-d'

type Props = {
  content: PrismicStartPageSingle['data']
}

export const CLanding = ({ content }: Props) => {
  const pushSegment = () => {
    if (window.analytics) {
      window.analytics.track('Quiz Started')
    }
  }

  const handleGetStartedButtonClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Quiz Start',
        placement: 'quiz_website',
      },
    })
  }

  const initAnimation = () => {
    const tl = gsap.timeline({})
    gsap.set(
      '.c-landing__intro-eyebrow, .c-landing__intro-heading, .c-landing__intro-body, .c-landing__intro-button ',
      {
        opacity: 0,
        transformOrigin: 'center center',
        scale: 1,
      }
    )
    gsap.set('.c-landing__intro-heading', { filter: 'blur(5px)' })
    tl.to('.c-landing__intro-heading', {
      opacity: 1,
      duration: 0.6,
      delay: 1.2,
      scale: 1,
    })

    tl.to('.c-landing__intro-heading', {
      opacity: 1,
      duration: 0.6,
      delay: 0.42,
      scale: 1,
      filter: 'blur(0px)',
    })
    tl.to('.c-landing__intro-body, .c-landing__intro-eyebrow', {
      opacity: 1,
      duration: 0.5,
      scale: 1,
    })
    tl.to('.c-landing__intro-button', {
      opacity: 1,
      duration: 0.5,
      scale: 1,
    })
  }

  useEffect(() => {
    initAnimation()
  }, [])

  return (
    <section className="c-landing__hero relative flex items-center justify-center w-full min-h-screen px-4 pt-20 pb-16 md:px-8 relative">
      <picture>
        <source media="(min-width:900px)" srcSet={content.background.url} />
        <source
          media="(min-width:500px)"
          srcSet={content.background.mid?.url}
        />
        <img
          src={content.background.mobile?.url}
          alt="Background image"
          className="absolute top-0 left-0 w-full h-full object-cover z-10"
        />
      </picture>
      <div className="flex flex-col items-center justify-center max-w-[672px] z-20">
        <span className="c-landing__intro-eyebrow mb-1 text-p-sm font-bold tracking-wide text-center text-white uppercase">
          {content.eyebrow}
        </span>
        <h1 className="c-landing__intro-heading max-w-lg mb-4 text-h4 font-light leading-tight text-center text-neutral-white md:text-h3">
          {content.heading}
        </h1>
        <p className="c-landing__intro-body mb-8 text-p font-semibold leading-normal text-center text-neutral-white">
          {content.body}
        </p>
        <div onClick={pushSegment}>
          <AButton
            className="c-landing__intro-button"
            type="link"
            href="/quiz"
            onClick={handleGetStartedButtonClick}
            style="solidToOutline"
            color="white"
            size="lg"
          >
            <span>{content.button_text}</span>
          </AButton>
        </div>
        <span className="c-landing__intro-body text-p text-neutral-white mt-4">
          {content.estimation}
        </span>
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

const styles = css`
  .c-landing__hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: black;
    z-index: 15;
  }
`
